import React from 'react'
import Header from '../Header'

function Home() {
    return (
        <>
         <Header></Header>
         <div>
            Chat App.... 2!
         </div>
        </>
       
    )
}

export default Home
